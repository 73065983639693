import './index.css'
import React, { useEffect, useState } from 'react';
import { Button, Space, Form, Input, message, Modal } from 'antd';
import instance from './request/api';
import { Link } from 'react-router-dom'

const { confirm } = Modal;


const Add_user = () => {

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        // 清空缓存
        window.localStorage.removeItem('email')
    }, [])
    // 保存输入的邮箱
    const getRefForm = (e) => {
        window.localStorage.setItem('email', e)
    }
    // 确认密码验证
    const validatePws = ({ getFieldValue }) => {
        return {
            validator: (_, value) => {
                if (getFieldValue('password') === value) {
                    return Promise.resolve();
                }
                if (!value || value == '') {
                    return Promise.reject(new Error("请输入确认密码!"))
                }
                return Promise.reject(new Error("两次输入的密码不一致!"))
            }
        }
    }

    const onFinish = (values) => {
        let val = { ...values };
        console.log(val)
        confirm({
            title: '提示',
            content: '是否确认找回密码？',
            cancelText: '取消',
            okText: '确认',
            onOk() {
                okReset(val)
            },
            onCancel() {
                console.log('Cancel');
            },
        });

    };
    // 确认找回密码
    const okReset = (val) => {
        setLoading(true)
        instance.post('/api/v1/sso/account/send-password-reset-code', val).then(res => {
            setLoading(false)
            message.success(res.message);
        }).catch(err => {
            setLoading(false)
            message.error(err.response.data.error.message);
            console.log(err)
        })
    }
    const onFinishFailed = (errorInfo) => {

        console.log('Failed:', errorInfo);
    };
    return (
        <div className='add_user'>
            <div className='add_user_box'>
                <p className='add_user_title'>找回或修改密码</p>
                <Form
                    name="basic"
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    size="large"
                >
                    <Form.Item
                        name="email"
                        rules={[{ required: true, message: '邮箱格式错误!', type:'email'}]}
                    >
                        <Input placeholder='请输入邮箱号' onChange={(e) => getRefForm(e.target.value)} />
                    </Form.Item>


                    <Form.Item>
                        <Button type="primary" htmlType="submit" style={{ width: '100%' }} loading={loading}>
                            找回密码
                        </Button>
                    </Form.Item>
                </Form>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Link to={'/'} style={{ textDecoration: 'none', color: '#1677ff', fontSize: '14px' }}>返回登陆页</Link>
                </div>
            </div>
        </div>
    )
}
export default Add_user;