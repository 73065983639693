
import './index.css'
import logo from './assets/images/logo1.png'
import instance from './request/api';
import LoginName from './components/Login/loginName';
import LoginPhone from './components/Login/loginPhone';
import { Link, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { message } from 'antd';


function Login() {
    useEffect(() => {
        document.title = '接口信息管理'
        let url = window.location.href;
        let ret = url.split('ReturnUrl=');
        if (ret.length > 1) {
            setReturnUrl(decodeURIComponent(ret[1]));
            return;
        }
        instance.get('http://api.admin.datamonitor.shop/api/v1/api_manage/consume_statistics').then(data => {
            console.log('data:', data)
        }).catch(err => {
            if (err.response.status == 401 && ret.length <= 1) {
                let errUrl = err.response.data.value.loginUrl.split('ReturnUrl=');
                window.location.href = `https://sso.datamonitor.shop/ReturnUrl=${decodeURIComponent(errUrl[1])}`
            }
        })
    }, [])
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    // 转向地址
    const [ReturnUrl, setReturnUrl] = useState(null);
    // tab切换
    const [tab, setTab] = useState(0)
    // 自动登录
    const [check, setCheck] = useState(false)
    const clickTab = (type) => {
        setTab(type)
    }
    // 表单提交成功
    const onChildFinish = (e) => {
        setLoading(true)
        console.log('Success:', e);
        instance.post('/api/account/login', {
            userNameOrEmailAddress: e.username,
            password: e.password,
            rememberMe: check,
        }).then(data => {
            setLoading(false)
            console.log(data)
            if (data.result != 1) {
                console.log('错误：')
                message.error(data.description);
            } else {
                window.location.href = ReturnUrl
                
            }
        }).catch(err => {
            setLoading(false)
            console.log('出错了：', err)
        })
    }
    // 勾选自动登录
    const onChecks = (e) => {
        setCheck(e)
    }

    return (
        <div className="login">
            <div style={{ width: '340px' }}>
                {/* logo */}
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <img src={logo} style={{ width: '230px' }}></img>
                </div>
                <div className='login_tab'>
                    <div className={tab == 0 ? 'login_tab_item cur' : 'login_tab_item'} onClick={() => clickTab(0)}>账号密码登录</div>
                    {/* <div className={tab == 1 ? 'login_tab_item cur' : 'login_tab_item'} onClick={() => clickTab(1)}>手机号登录</div> */}
                </div>
                {
                    tab == 0 ?
                        <LoginName onChildFinish={onChildFinish} onChildCheck={onChecks} loading={loading} />
                        : ''
                        // <LoginPhone onChildFinish={onChildFinish} onChildCheck={onChecks} />
                }
            </div>
        </div>
    )
}
export default Login;