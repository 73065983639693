import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { Button, Form, Input, Checkbox } from 'antd';


function loginPhone(props) {
    // 表单提交成功
    const onFinish = (values) => {
        props.onChildFinish(values)
    };
    // 表单提交失败
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    // 勾选自动登录
    const onChange = (e) => {
        props.onChildCheck(e.target.checked)
    };
    return (
        <Form
            name="basic"
            initialValues={{
                remember: true,
            }}
            style={{ marginTop: '26px' }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <Form.Item
                name="username"
                rules={[
                    {
                        required: true,
                        message: '请输入手机号',
                    },
                ]}
            >
                <Input size="large" placeholder="手机号" prefix={<UserOutlined />} />
            </Form.Item>

            <Form.Item
                name="password"
                rules={[
                    {
                        required: true,
                        message: '请输入验证码',
                    },
                ]}
            >
                <Input.Password placeholder="验证码" size="large" prefix={<LockOutlined />} />
            </Form.Item>
            <div className='login_rememberMe'>
                <Checkbox onChange={onChange}>自动登录</Checkbox>
                <span>忘记密码</span>
            </div>
            <Form.Item>
                <Button type="primary" size="large" htmlType="submit" style={{ width: '100%' }}>
                    登录
                </Button>
            </Form.Item>
            <div style={{display:'flex',alignItems:'center'}}>
                <p>其他登录方式</p>
                <img style={{width:'20px',height:'20px',borderRadius:'50%',marginLeft:'5px'}}
                 src='https://gimg3.baidu.com/search/src=https%3A%2F%2Fzhengxin-pub.cdn.bcebos.com%2Flogopic%2Fa9936a369e82e0c6c42112674a5220e8_fullsize.jpg&refer=http%3A%2F%2Fwww.baidu.com&app=2021&size=w256&n=0&g=0n&q=100&fmt=auto?sec=1686675600&t=43e0a52c2910b059695b6b398ae4bf51'></img>
            </div>
        </Form>
    )
}
export default loginPhone;