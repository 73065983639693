import React, { lazy } from 'react'
import { Navigate } from 'react-router-dom'


import Login from '../login'
import User from '../add_user'
import Account from '../Account'
import ResetPassword from '../ResetPassword'

const router = [
    {
        path: '/?ReturnUrl',
        element: <Login />
    },
    {
        path: '/add_user',
        element: <User />
    },
    {
        path: '/Account',
        element: <Account />
    },
    {
        path: '/Account/ResetPassword',
        element: <ResetPassword />
    },
    {
        path: '*',
        element: <Login />
    },
]
export default router