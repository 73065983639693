import axios from "axios";

// https://sso.datamonitor.shop/
const instance = axios.create({
  baseURL: "https://api-sso.datamonitor.shop",
  timeout: 30000, //超时时间为10秒
  // withCredentials:true
});

// 请求拦截器
instance.interceptors.request.use(
  (config) => {
    // 在发送请求前作些处理，添加公共参数
    config.params = {
      ...config.params,
    };
    return config;
  },
  (error) => Promise.reject(error)
);

// 响应拦截器
instance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          console.log("未登录");
          break;
        case 404:
          console.log("页面不存在");
          break;
        default:
          console.log(`请求错误：${error.response.status}`);
      }
    } else if (error.request) {
      console.log("请求超时，请检查网络是否正常");
    } else {
      console.log("请求错误：", error.message);
    }
    return Promise.reject(error);
  }
);
export default instance;
